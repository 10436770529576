@import '@react-pdf-viewer/core/lib/styles/index.css';
@import '@react-pdf-viewer/default-layout/lib/styles/index.css';
@import '@react-pdf-viewer/search/lib/styles/index.css';
@import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
@import '@react-pdf-viewer/toolbar/lib/styles/index.css';

.rpv-search__highlight {
  @apply !rounded-sm !bg-info !opacity-20;
}

div[aria-describedby='rpv-core__tooltip-body-toolbar-more-actions'] {
  @apply hidden;
}

.rpv-default-layout__toolbar {
  @apply px-5;
}

[type='text'].rpv-core__textbox  {
  background-color: var(--rpv-core__textbox-background-color);
  border: 1px solid var(--rpv-core__textbox-border-color);
}
